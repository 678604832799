
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Auth/Login";
import FrontOffice from "./pages/FrontOffice/FrontOffice";
import GeneralJournal from "./pages/BackOffice/GeneralJournal";
import Disbursement from "./pages/BackOffice/Disbursement";
import BillsPayment from "./pages/BackOffice/BillsPayment";
import CashReceipt from "./pages/BackOffice/CashReceipt";
import CustomReports from "./pages/Reports/CustomReports";
import SLProfile from "./pages/Others/SLProfile";
import ClientProfile from "./pages/ClientProfile/ClientProfile";
import ClientProfileSearch from "./pages/ClientProfile/ClientProfileSearch";
import { useState } from "react";




function App() {

  return (

      <Routes>
       
      <Route path="/" element={<Login />} >
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/clientprofile" element={<ClientProfile />} />
      <Route path="/clientprofile/search" element={<ClientProfileSearch />} />

      <Route path="/frontoffice" element={<FrontOffice />} />
      <Route path="/generaljournal" element={<GeneralJournal />} />
      <Route path="/disbursement" element={<Disbursement />} />
      <Route path="/billpayment" element={<BillsPayment />} />
      <Route path="/cashreceipt" element={<CashReceipt />} />
      <Route path="/customreports" element={<CustomReports />} />

      <Route path="/slprofile" element={<SLProfile />} />


      <Route path="*" element={<h1>Page not found</h1>} />
    </Routes>

  );
}

export default App;
