import React from 'react'

function DashboardContent() {
  return (
    <div className="container-fluid">
          <div className='row'>
              <div className='col-md-12'>
                 API URL: <b> {window.localStorage.getItem('api_url')}</b>
              </div>
              <div className='col-md-12'>
                 API URL PREFIX: <b>{window.localStorage.getItem('api_prefix')} </b>
              </div>
              <div className='col-md-12'>
                 APP KEY:  <b>{window.localStorage.getItem('app_key')}</b>
              </div>
              <div className='col-md-12'>
                 APP SECRET: <b>{window.localStorage.getItem('app_secret')}</b>
              </div>
          </div>
    </div>
  )
}

export default DashboardContent