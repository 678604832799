import React from 'react'

function ClientProfileSearchContnent() {
  return (
    <div className="container-fluid">
        
    </div>
  )
}

export default ClientProfileSearchContnent