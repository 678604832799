import React from 'react'

import LoginContent from '../../contents/Other/LoginContent'
import TopBar from '../../contents/TopBar'
import '../../Login.css'

function Login() {

  return (
    
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"    
    >
      <TopBar />

      <LoginContent />

    


    </div>
  )
}

export default Login