import React from 'react'

function HeaderTopMenu() {
    return (
        <ul className="navbar-nav float-end endTopPoint ">    
            <li className="nav-item dropdown">
                <a title="Setup" className="nav-link dropdown-toggle fw-bold" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-end user-dd animated"
                    aria-labelledby="navbarDropdown"
                >
                    <li><a className="dropdown-item" href="#">
                        Chart of Accounts

                    </a></li>
                    <li><a className="dropdown-item" href="#">
                        CONSO Chart of Accounts
                    </a></li>
                    <li><a className="dropdown-item" href="#">
                        Departments
                    </a></li>
                 
                    <li className="dropdown-submenu">
                        <a className="sub-menu" tabindex="-1" href="#">Subsidiary Ledger (SL) <span className="fa fa-caret-right fa-lg"></span></a>
                        <ul className="dropdown-menu dropdown-menu-end user-dd animated">
                            <li><a className="dropdown-item" tabindex="-1" href="#">S/L Class</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">S/L Types</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">S/L Entry Type</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">S/L Status</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">Bank Code Table </a>
                    </li>
                    <li className="dropdown-submenu">
                        <a className="sub-menu" tabindex="-1" href="#">Client Groups <span className="fa fa-caret-right fa-lg"></span></a>
                        <ul className="dropdown-menu dropdown-menu-end user-dd animated">
                            <li><a className="dropdown-item" tabindex="-1" href="#">1</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">2</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">3</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">4</a></li>
                        </ul>
                    </li>
                    <li className="dropdown-submenu">
                        <a className="sub-menu" tabindex="-1" href="#">Loan Related <span className="fa fa-caret-right fa-lg"></span></a>
                        <ul className="dropdown-menu dropdown-menu-end user-dd animated">
                            <li><a className="dropdown-item" tabindex="-1" href="#">1</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">2</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">3</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">4</a></li>
                        </ul>
                    </li>
                    <li className="dropdown-submenu">
                        <a className="sub-menu" tabindex="-1" href="#">Check Book <span className="fa fa-caret-right fa-lg"></span></a>
                        <ul className="dropdown-menu dropdown-menu-end user-dd animated">
                            <li><a className="dropdown-item" tabindex="-1" href="#">1</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">2</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">3</a></li>
                            <li><a className="dropdown-item" tabindex="-1" href="#">4</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">Code Storage </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">Holidays </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">Email Settings </a>
                    </li>
                </ul>
            </li>

            <li className="nav-item dropdown">
                <a title="Reports" className="nav-link dropdown-toggle fw-bold" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-pie-chart fa-lg" aria-hidden="true"> </i>
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-end user-dd animated"
                    aria-labelledby="navbarDropdown"
                >
                    <li><a className="dropdown-item" href="#">#</a></li>
                    <li><a className="dropdown-item" href="#">#</a></li>
                    <li><a className="dropdown-item" href="#">#</a></li>
                </ul>
            </li>

            <li className="nav-item dropdown">
                <a title="Tools" className="nav-link dropdown-toggle fw-bold" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-wrench fa-lg" aria-hidden="true"></i>
                </a>
                <ul 
                    className="dropdown-menu dropdown-menu-end user-dd animated"
                    aria-labelledby="navbarDropdown"
                >
                    <li><a className="dropdown-item" href="#">#</a></li>
                    <li><a className="dropdown-item" href="#">#</a></li>
                    <li><a className="dropdown-item" href="#">#</a></li>
                </ul>
            </li>

            <li className="nav-item dropdown" >
                <a
                    title="User Account"
                    className="
        nav-link
        dropdown-toggle
        text-muted
        waves-effect waves-dark
        pro-pic
      "

                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img
                        src="../assets/images/users/1.jpg"
                        alt="user"
                        className="rounded-circle"
                        width="31"
                    />
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-end user-dd animated"
                    aria-labelledby="navbarDropdown"
                >

                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="javascript:void(0)"
                    ><i className="mdi mdi-settings me-1 ms-1"></i> Account
                        Setting</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/login"
                    ><i className="fa fa-power-off me-1 ms-1"></i> Logout</a>
                    <div className="dropdown-divider"></div>

                </ul>
            </li>

        </ul>
    )
}

export default HeaderTopMenu