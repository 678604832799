
import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import CustomReportContent from '../../contents/Report/CustomReportContent'

function CustomReports() {
  return (
    <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
>

    <Header title="Customized" />
    <div>
    <Sidebar />

    <div className="page-wrapper">
        <CustomReportContent/>
   
    </div>
</div>
</div>
  )
}

export default CustomReports