import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';


function LoginContent() {

  const [companyName, setCompanyName] = useState("");
  const [dataDisabled, setdataDisabled] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputs, setInputs] = useState({
    api_url: '',
    api_prefix: '',
    app_key: '',
    app_secret: ''
  });

  useEffect(() => {

    const api_url = window.localStorage.getItem('api_url');
    const api_prefix = window.localStorage.getItem('api_prefix');
    const app_key = window.localStorage.getItem('app_key');
    const app_secret = window.localStorage.getItem('app_secret');

    if (api_url === null || api_url === '') {
      setdataDisabled(true);
    }
    if (api_prefix === null || api_prefix === '') {
      setdataDisabled(true)
    }

    if (app_key === null || app_key === '') {
      setdataDisabled(true)
    }
    if (app_secret === null || app_secret === '') {
      setdataDisabled(true)
    }

    setInputs({
      api_url: api_url,
      api_prefix: api_prefix,
      app_key: app_key,
      app_secret: app_secret
    })

    setCompanyName("DEMO COMPANY");

  }, []);




  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    window.localStorage.setItem('api_url', e.target.api_url.value);
    window.localStorage.setItem('api_prefix', e.target.api_prefix.value);
    window.localStorage.setItem('app_key', e.target.app_key.value);
    window.localStorage.setItem('app_secret', e.target.app_secret.value);
    window.location.reload();
  }



  return (
    <div
      className="
      auth-wrapper
      d-flex
      no-block
      justify-content-center
      align-items-center

    "
    >
      <div className="auth-box ">
        <div id="loginform">
          <div className="text-center h-50 login-header" >
            <span className="text-center">
              <img src="assets/images/logo.png"
                className="img-fluid animate__animated animate__fadeInDown  animate__faster" id="img-logo" width="500"
                alt="logo" /> <span className="tm">TM</span>
            </span>
            <h3 className="web-text text-light fw-bold animate__animated animate__fadeInDown zero_5 animate__faster">{companyName}
            </h3>
          </div>

          {dataDisabled === false ? <form
            className="form-horizontal mt-1 active p-3 animate__animated animate__fadeInDown animate__delay-1s animate__faster "
            id="loginform"
            action="/dashboard"
          >

            <div className="row">
              <div className="col-12 ">
                <div className="input-group mb-3 border-login">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-default text-white h-100"
                      id="basic-addon1"
                    ><i className="mdi mdi-account fs-4 text-dark"></i></span>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    required

                  />
                </div>
                <div className="input-group mb-3 border-login">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-default text-white h-100"
                      id="basic-addon2"
                    ><i className="mdi mdi-lock fs-4 text-dark"></i ></span>
                  </div>

                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-12">

                <div className="form-group">
                  <div className="text-end">

                    <button

                      className="btn btn-lg btn-success float-end text-white w-100 h4"
                      id="login_button"
                      type="submit"
                    >
                      LOGIN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form> : null}

        </div>
      </div>

      <div>

      </div>
      <div className='footer-h text-right' >
        <b>IACCS-X </b> @2023 coreDev Solutions, Inc.

      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} >
            <div className="modal-body">
              <div className='row'>

                <div className="col-md-12">
                  <label for="fname" className="text-start control-label col-form-label-sm ">API URL:</label>
                  <input type="text" className="form-control form-control-sm"
                    value={inputs.api_url || ""}
                    name="api_url"
                    onChange={handleChange} />
                </div>

                <div className="col-md-12 pt-1">
                  <label for="fname" className="text-start control-label col-form-label-sm ">API URL
                    Prefix:</label>
                  <input type="text" className="form-control form-control-sm"
                    value={inputs.api_prefix || ""}
                    name="api_prefix"
                    onChange={handleChange} />
                </div>
                <div className="col-md-12 pt-1 ">
                  <label for="fname" className="text-start control-label col-form-label-sm ">Application
                    Key:</label>
                  <input type="text" className="form-control form-control-sm"
                    value={inputs.app_key || ""}
                    name="app_key"
                    onChange={handleChange} />
                </div>

                <div className="col-md-12 pt-1">
                  <label for="fname" className="text-start control-label col-form-label-sm ">Application
                    Secret:</label>
                  <input type="text" className="form-control form-control-sm"
                    value={inputs.app_secret || ""}
                    name="app_secret"
                    onChange={handleChange} />

                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>

              <button type="submit" className="btn btn-primary"> Save Setting</button>
            </div>
          </form>
        </Modal.Body>

      </Modal>
      <div className="footer-right">
        <button type="button" title="Setting" className="btn text-secondary text-white  fa fa-cog fa-2x  btn-setting active"
          onClick={handleShow}> </button>
      </div>
      <div className='footer-left' >

        {/* <b><a href='http://www.backtomainmenu.com/' className='text-white' title='Go Back' > <i className="fa fa-sign-out fa-2x" style={{ transform: "rotate(180deg)" }} aria-hidden="true"></i></a> </b> */}

      </div>
    </div>
  )
}

export default LoginContent