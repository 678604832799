import React from 'react'
import HeaderTopMenu from '../components/HeaderTopMenu'

function Header(props) {

  return (
    <header className="topbar" data-navbarbg="skin5">
      <nav className="navbar fixed-top top-navbar navbar-expand-md navbar-dark">
        <div className="navbar-header" data-logobg="skin5">

          <a className="navbar-brand" href="/dashboard">

            <b className="logo-icon ps-2">

              <img
                src="../assets/images/logo-icon.png"
                alt="homepage"
                className="light-logo"
                width="25"
              />
            </b>

            <span className="logo-text ms-2">
              <img
                src="../assets/images/logo-text.png"
                alt="homepage"
                className="light-logo"
              />
            </span>

          </a>

          <a
            className="nav-toggler waves-effect waves-light d-block d-md-none"
            href="javascript:void(0)">
            <i className="ti-menu ti-close"></i ></a>
        </div>
        <div
          className="navbar-collapse collapse"
       
          data-navbarbg="skin5"
          id="navbarSupportedContent"
          
        >
          <ul className="navbar-nav float-start me-auto startTopPoint">
            <li className="nav-item d-none d-lg-block">
              <a id="tooglerbutton"
            
                className="nav-link sidebartoggler waves-effect waves-light"
                href="javascript:void(0)"
                data-sidebartype="mini-sidebar"
              ><i className="mdi mdi-menu font-24"></i></a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"

                id="header-module-title"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >

                <span className="d-block d-md-none"
                ><i className="fa fa-plus"></i></span>  {props.title}
              </a>

            </li>
            <li className='grab'>   
              <div className='d-full-width '>
                <div className='draggable'>
                  <br />
                  <br />
                </div>
                
              </div>
            </li>
          </ul>

          <HeaderTopMenu/>
      
        </div>
      </nav>
    </header>
  )
}

export default Header