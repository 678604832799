import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import DisbursementContent from '../../contents/BackOffice/DisbursementContent'

function Disbursement() {
  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"
    >

      <Header title="Disbursement" />
      <div>
      <Sidebar />

      <div className="page-wrapper">
        <DisbursementContent />
        {/* <Footer /> */}
      </div>
</div>
    </div>
  )
}

export default Disbursement