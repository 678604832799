import React from 'react'
function Sidebar() {
  return (
    <aside className="left-sidebar" data-sidebarbg="skin5">

      <div className="scroll-sidebar">

        <nav className="sidebar-nav">
          <ul id="sidebarnav" className="pt-1">
            <li className="sidebar-item">
              <input className='form-control form-control-sm bg-dark text-white border border-secondary w-100' placeholder='Search..' />
            </li>
            <li className="sidebar-item">
              <a  title="Dashboard"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                href="/dashboard"
                aria-expanded="false"
              > <i className="fa fa-tachometer"> </i><span className="hide-menu">Dashboard</span></a>
            </li>
            <li className="sidebar-item">
              <a
               title="Client Profile"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                href="/clientprofile"
                aria-expanded="false"
              ><i className="fa fa-users"></i><span className="hide-menu">Client Profile</span></a>
            </li>
            <li className="sidebar-item">
              <a  title="Front Office"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                href="/frontoffice"
                aria-expanded="false"
              ><i className="fa fa-address-book"></i><span className="hide-menu">Front Office</span></a>
            </li>
            <li className="sidebar-item">
              <a  title="Back Office"
                className="sidebar-link has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
                aria-expanded="false"
              ><i className="fa fa-book"></i><span className="hide-menu">Back Office</span></a>
              <ul aria-expanded="false" className="collapse first-level">
                <li className="sidebar-item">
                  <a  title="General Journal"
                  href="/generaljournal" className="sidebar-link"
                  ><i className="fa fa-sticky-note"></i><span className="hide-menu"> General Journal </span></a>
                </li>
                <li className="sidebar-item">
                  <a  title="Disbursement" 
                  href="/disbursement" className="sidebar-link"
                  ><i className="fa fa-credit-card-alt"></i><span className="hide-menu"> Disbursement </span></a>
                </li>
                <li className="sidebar-item">
                  <a   title="Bills Payment"
                  href="/billpayment" className="sidebar-link"
                  ><i className="fa fa-credit-card"></i><span className="hide-menu"> Bills Payment </span></a>
                </li>
                <li className="sidebar-item">
                  <a 
                   title="Cash Receipts"
                  href="/cashreceipt" className="sidebar-link"
                  ><i className="fa fa-money"></i><span className="hide-menu"> Cash Receipts </span></a>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <a  title="Reports"
                className="sidebar-link has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
                aria-expanded="false"
              ><i className="fa fa-file-text"></i><span className="hide-menu">Reports</span></a>
              <ul aria-expanded="false" className="collapse first-level">
                <li className="sidebar-item">
                  <a  title="Customized"
                   href="/customreports" className="sidebar-link"
                  ><i className="fa fa-pencil-square-o"></i><span className="hide-menu"> Customized </span></a>
                </li>

              </ul>
            </li>
             
          </ul>
          
        </nav>

      </div>

    </aside>

  )
}

export default Sidebar