import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import ClientContent from '../../contents/ClientProfile/ClientContent'

function ClientProfile() {
    return (
        <div
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin5"
            data-sidebartype="full"
            data-sidebar-position="absolute"
            data-header-position="absolute"
            data-boxed-layout="full"
        >
            <Header title="Client Profile" />

            <div>
                <Sidebar />

                <div className="page-wrapper">

                    <ClientContent />

                </div>
            </div>

        </div>
    )
}

export default ClientProfile